import React from 'react'
import { useSelector } from 'react-redux';
import { Route } from "react-router-dom";
import SuperUser from '../WebView/SuperAdmin/Users_old/SuperUser';
import AddUser from '../WebView/SuperAdmin/Users_old/AddUser';
import Roads from '../WebView/Roads/Roads';
import RoadDetails from '../WebView/Roads/RoadDetails/RoadDetails';
// import DailyAnalytics from '../WebView/Analytics/Daily/DailyAnalytics';
import CompleteAnalytics from '../WebView/Analytics/Complete/CompleteAnalytics';
import Dashbaord from '../WebView/Dashboard/Dashbaord';
import LiveAlerts from '../WebView/LiveAlerts/LiveAlerts';
import MapMyIndia from '../Component/Map/MapMyIndia';
import MapView from '../WebView/MapView/MapView';
import Profile from '../WebView/Account/Profile/Profile';
import Zone from '../WebView/SuperAdmin/SuperZone/Zone/Zone';
import Dpiu from '../WebView/SuperAdmin/SuperZone/Dpiu/Dpiu';
import LiveView from '../WebView/MapView/LiveView/LiveView';
import TrackAndPlay from '../WebView/MapView/TrackAndPlay/TrackAndPlay';
import DetailsAnalytics from '../WebView/Analytics/Complete/Details/DetailsAnalytics';
import DetailsOld from '../WebView/Analytics/Complete/Details_old/Details';

export default function Routes() {
  const reduxUser = useSelector(state => state.AuthReducer)

  return (
    <div>
      {
        reduxUser?.doc ? <div>
          {/* <Route path="/" exact component={reduxUser?.doc?.userType !== 'SuperAdmin' ? Dashbaord : SuperUser} />
          <Route path="/dashboard" exact component={reduxUser?.doc?.userType !== 'SuperAdmin' ? Dashbaord : SuperUser} /> */}
          <Route path="/sup/user/add" exact component={AddUser} />
          <Route path="/sup/zone" exact component={Zone} />
          <Route path="/sup/zone/:id" exact component={Dpiu} />
          <Route path="/roads" exact component={Roads} />
          <Route path="/test" exact component={MapMyIndia} />
          <Route path="/roads/details/:id" exact component={RoadDetails} />
          <Route path="/analytics/complete" exact component={CompleteAnalytics} />
          <Route path="/analytics/complete/:id" exact component={DetailsOld} />
          <Route path="/live-alerts" exact component={LiveAlerts} />
          <Route path="/" exact component={MapView} />
          <Route path="/mapview" exact component={MapView} />
          {/*  */}
          <Route path="/account" exact component={Profile} />
          <Route path="/mapview/live/:id" exact component={LiveView} />
          <Route path="/mapview/track-and-play/:id" exact component={TrackAndPlay} />
        </div> : null
      }

    </div>
  )
}
