export const BaseUrl = "https://headsupapi.imzrrda.com/api/";
export const MMIURL = "http://pmgsy.headsupcorporation.com/api/";
// export const BaseUrl = "http://192.168.1.52:3002/api/";
export const INTouchURL = 'https://intouch.mappls.com/iot/api'

export const IMZBaseURL = 'https://api.imzcorporate.com/api/ret/allrequest/'
export const LoginApi = BaseUrl + 'Login'
export const AddUserApi = BaseUrl + 'AddUser'
export const getUsers = BaseUrl + 'getUsers'
export const RemoveUser = BaseUrl + ''

export const GetRoads = BaseUrl + 'getFinalData'
export const getCompleteRrdaReportsLite = BaseUrl + 'getCompleteRrdaReportsLite'
// export const getAnalyticsDaily = BaseUrl + 'removeUnwanted'
export const getAnalyticsDaily = BaseUrl + 'getAnalyticsDaily'
export const MapToken = 'https://outpost.mapmyindia.com/api/security/v2.2.3/oauth/token'
export const GetToken = MMIURL + 'getRRDATokenS'
export const MMIToken = "3265f5f1-159b-40f8-8f1c-1eaf2ddc198e"
export const LiveDeviceData = INTouchURL + '/devices'
export const GetTracks = BaseUrl + 'GetDriveMultiple'

export const AutoCompleteUPRRDA = BaseUrl + 'AutoCompleteUPRRDA'

// export const GetTracks = BaseUrl + 'removeUnwanted'
export const getMMIData = BaseUrl  + 'getMMIData'
export const getMMIAl = BaseUrl  + 'getMMIDevices'
export const getDevice = BaseUrl  + 'getDevice'

export const getZone = BaseUrl + 'getZone'
export const AddZone = BaseUrl + 'AddZone'
export const ZoneRemove = BaseUrl + 'ZoneRemove'

// MMI API LIST

export const GetAlertLogs = 'https://intouch.mappls.com/iot/api/alarm/alarmLog/?'


export const buildVersion = '1.32' 
