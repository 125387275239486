import { DashboardIcon, DownloadCertificateIcon, MapviewIcon, MapviewIcon1, ReportIcon, ReportIcon1, TruckIcon, UsersIcon, alertIcon } from "../Constant/Icon/Icon"

const ReportChild = [
    // { _id: 1, title: 'Alert Log Reports', url: '/report/alertreports', icon: ReportIcon1, permission: [], active: true },
    // { _id: 1, title: 'Live Vehicle Locaiton Report', url: '/report/live-vehicle-location-report', icon: ReportIcon1, permission: [], active: true },
    { _id: 1, title: 'Working Hour & Efficiency Report', url: '/report/working-hour-and-efficiency-report', icon: ReportIcon1, permission: [], active: true },
    // { _id: 1, title: 'Complete Detailed Activity Report', url: '/report/complete-detailed-activity-report', icon: ReportIcon1, permission: [], active: false },
    // { _id: 1, title: 'Daily Detailed Activity Report', url: '/report/daily-detailed-activity-report', icon: ReportIcon1, permission: [], active: true },
    // { _id: 1, title: 'Complete Trip Wise Report', url: '/report/complete-trip-wise-report', icon: ReportIcon1, permission: [], active: true },
    // { _id: 1, title: 'Daily Trip Wise Report', url: '/report/daily-trip-wise-report', icon: ReportIcon1, permission: [], active: true },
    // { _id: 1, title: 'History Report', url: '/report/history-report', icon: ReportIcon1, permission: [], active: true },
    // { _id : 1, title : 'Complete Vehicle Summary Report', url : '/report/vehicle-summary-report', icon: ReportIcon1 , permission :[], active : true },
    // { _id: 1, title: 'Vehicle Stoppage Report', url: '/report/vehicle-stoppage-report', icon: ReportIcon1, permission: [], active: true },
    // { _id: 1, title: 'Vehicle Log Report', url: '/report/vehicle-log-report', icon: ReportIcon1, permission: [], active: true },
    // { _id: 1, title: 'Vehicle Not on Site', url: '/report/vehicle-not-on-site', icon: ReportIcon1, permission: [], active: true },
]

export const AnalyticsChild = [
    // { _id: 0, title: 'Daily Analytics', url: '/analytics/daily', icon: ReportIcon1, permission: [], active: true },
    { _id: 1, title: 'Complete Compaction Report', url: '/analytics/complete', icon: ReportIcon1, permission: [], active: true },
]

export const navLinks = [
    // { _id: 99, title: 'Dashboard', url: '/dashboard', icon: DashboardIcon, child: [], permission: ['Zone', 'zone', 'DPIU', 'dpiu', 'PIU', 'piu'], active: false },
    { _id: 0, title: 'Map View', url: '/mapview', icon: MapviewIcon, child: [], permission: ['Zone', 'zone', 'DPIU', 'dpiu', 'PIU', 'piu'], active: false },
    // { _id: 0, title: 'Reports', url: '', icon: ReportIcon, child: ReportChild, permission: ['Zone', 'zone', 'DPIU', 'dpiu', 'PIU', 'piu'], active: false },
    { _id: 0, title: 'Compaction Report', url: '/analytics/complete', icon: ReportIcon, child: [], permission: ['Zone', 'zone', 'DPIU', 'dpiu', 'PIU', 'piu'], active: false },
    { _id: 99, title: 'Alerts', url: '/live-alerts', icon: alertIcon, child: [], permission: ['Zone', 'zone', 'DPIU', 'dpiu', 'PIU', 'piu'], color: 'text-error', active: false },
    { _id: 0, title: 'Roads', url: '/roads', icon: MapviewIcon, child: [], permission: ['Zone', 'zone', 'DPIU', 'dpiu', 'PIU', 'piu'], active: false },
    { _id: 0, title: 'Zone', url: '/sup/zone', icon: UsersIcon, child: [], permission: ['SuperAdmin'], active: false },
    { _id: 0, title: 'Users', url: '/sup/users', icon: UsersIcon, child: [], permission: [], active: false },
    { _id: 0, title: 'Download Certificates', url: '/certificates', icon: DownloadCertificateIcon, child: ['Zone', 'zone', 'DPIU', 'dpiu', 'PIU', 'piu'], permission: [], active: false },
    { _id: 99, title: 'Device Reassign', url: '/device-reassign', icon: TruckIcon, child: [], permission: [], active: false },
    // { _id: 0, title: 'Compaction Form', url: '/report/conventional/create', icon: TruckIcon, child: [], permission: ['PIU', 'piu'], active: false },
]

